<template>
  <layout-selection-toolbar-teleport>
    <ul class="list-unstyled flex gap-0 lg:gap-3 items-center">
      <li v-if="hasOrganize">
        <u-dropdown :items="organizeItems">
          <core-selection-toolbar-button icon="i-ri-stack-line">Organize</core-selection-toolbar-button>
        </u-dropdown>
      </li>
      <li v-for="action in allActions">
        <core-selection-toolbar-button :icon="action.icon" @click="action.handler">{{action.label}}</core-selection-toolbar-button>
      </li>
    </ul>
  </layout-selection-toolbar-teleport>
</template>

<script setup>
  const props = defineProps({
    actions: Array,
    context: Object,
    contextType: String,
    userId: String
  });

  const fileActions = useFileActions();
  const selection = useSelection();
  const actionsEnum = fileActions.selectionToolbarActions;

  const hasOrganize = props.actions.includes(actionsEnum.organize);
  const hasMoveToAlbum = props.actions.includes(actionsEnum.moveToAlbum);

  const allActions = computed(() => [
    {
      label: 'Share',
      icon: COMMON_ICONS.share,
      handler: share,
      included: props.actions.includes(actionsEnum.share)
    },
    {
      label: 'Download',
      icon: COMMON_ICONS.download,
      handler: download,
      included: props.actions.includes(actionsEnum.download)
    },
    {
      label: 'Make Cover',
      icon: COMMON_ICONS.makeCover,
      handler: makeCover,
      included: props.actions.includes(actionsEnum.makeCover) && selection.count.value === 1
    },
    {
      label: 'Delete',
      icon: COMMON_ICONS.delete,
      handler: deleteFiles,
      included: props.actions.includes(actionsEnum.delete)
    },
    {
      label: 'Remove',
      icon: 'i-ri-close-circle-line',
      handler: deleteFiles,
      included: props.actions.includes(actionsEnum.removeFromContainer)
    },
    {
      label: 'Copy',
      icon: 'i-ri-file-copy-2-line',
      handler: copy,
      included: props.actions.includes(actionsEnum.copyToMyGallery)
    },
    {
      label: 'Restore',
      icon: 'i-ri-arrow-go-back-line',
      handler: restoreToLibrary,
      included: props.actions.includes(actionsEnum.restoreToLibrary)
    },
    {
      label: 'Delete Permanently',
      icon: 'i-ri-delete-bin-6-line',
      handler: purge,
      included: props.actions.includes(actionsEnum.purge)
    },
  ].filter(action => action.included));

  const organizeItems = [
    [
      {
        label: 'Add to Album',
        icon: 'i-ri-folder-add-line',
        click: addToAlbum
      },

      hasMoveToAlbum ? {
        label: 'Move to Album',
        icon: 'i-ri-folder-transfer-line',
        click: moveToAlbum
      } : null,
      {
        label: 'Add Tags',
        icon: COMMON_ICONS.tag,
        click: addTags
      },
      {
        label: 'Add People',
        icon: COMMON_ICONS.person,
        click: addToPeople
      },
      {
        label: 'Add to Favorites',
        icon: COMMON_ICONS.favoriteLine,
        click: addToFavorites
      },
      {
        label: 'Bulk Organize',
        icon: 'i-ri-stack-line',
        click: bulkOrganize
      }
    ].filter(Boolean)
  ];

  async function addToAlbum() {
    await fileActions.addMoveFilesToAlbum({files: selection.selected.value});
    selection.clear();
  }

  async function moveToAlbum() {
    await fileActions.addMoveFilesToAlbum({files: selection.selected.value, fromAlbumId: props.context.id});
    selection.clear();
  }

  async function addTags() {
    await fileActions.addTagsToFiles({files: selection.selected.value});
    selection.clear();
  }

  async function addToPeople() {
    await fileActions.addFilesToPeople({files: selection.selected.value});
    selection.clear();
  }

  async function addToFavorites() {
    await useFilesStore().addFilesToFavorites({files: selection.selected.value});
    useSuccessToast().add({
      description: `The selected ${selection.selected.value.length} files were successfully added to your Favorites.`
    });

    selection.clear();
  }

  async function bulkOrganize() {
    await fileActions.bulkOrganize({files: selection.selected.value});
    selection.clear();
  }

  async function share() {
    fileActions.share({files: selection.selected.value});
  }

  async function download() {
    await fileActions.downloadFiles({files: selection.selected.value, userId: props.userId});
  }

  async function deleteFiles() {
    await fileActions.deleteFiles({files: selection.selected.value, context: props.context, contextType: props.contextType});
    selection.clear();
  }

  async function makeCover() {
    const actions = {
      'album': fileActions.setFileAsAlbumCover,
      'tag': fileActions.setFileAsTagCover,
      'person': fileActions.setFileAsPersonCover
    };

    const action = actions[props.contextType];

    if (action) {
      await action({
        file: selection.selected.value[0],
        [props.contextType]: props.context
      });
    }
    selection.clear();
  }

  async function copy() {
    await fileActions.copyFilesToCurrentUserLibrary({files: selection.selected.value, userId: props.userId});
    selection.clear();
  }

  async function restoreToLibrary() {
    await fileActions.restoreFiles({files: selection.selected.value});
    selection.clear();
  }

  async function purge() {
    await fileActions.purgeFiles({files: selection.selected.value});
    selection.clear();
  }

</script>
